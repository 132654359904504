/**
* @license
* MyFonts Webfont Build ID 3549416, 2018-03-29T17:01:24-0400
*
* The fonts listed in this notice are subject to the End User License
* Agreement(s) entered into by the website owner. All other parties are
* explicitly restricted from using the Licensed Webfonts(s).
*
* You may obtain a valid license at the URLs below.
*
* Webfont: CoreRhino35Light by S-Core
* URL: https://www.myfonts.com/fonts/s-core/core-rhino/light/
* Copyright: Core Rhino 35 Light
*
* Webfont: CoreRhino45Regular by S-Core
* URL: https://www.myfonts.com/fonts/s-core/core-rhino/regular/
* Copyright: Core Rhino 45 Regular
*
* Webfont: CoreRhino55Medium by S-Core
* URL: https://www.myfonts.com/fonts/s-core/core-rhino/medium/
* Copyright: Core Rhino 55 Medium
*
* Webfont: CoreRhino65Bold by S-Core
* URL: https://www.myfonts.com/fonts/s-core/core-rhino/bold/
* Copyright: Core Rhino 65 Bold
*
* Webfont: CoreRhino75Heavy by S-Core
* URL: https://www.myfonts.com/fonts/s-core/core-rhino/heavy/
* Copyright: Core Rhino 75 Heavy
*
* Webfont: CoreRhino85Black by S-Core
* URL: https://www.myfonts.com/fonts/s-core/core-rhino/black/
* Copyright: Core Rhino 85 Black
*
*
* License: https://www.myfonts.com/viewlicense?type=web&buildid=3549416
* Licensed pageviews: 10,000
*
* © 2018 MyFonts Inc
*/

/* @import url("/count/3628e8"); */

/* @import must be at top of file, otherwise CSS will not work */
@font-face {
  font-family: 'CoreRhino35Light';
  font-weight: 300;
  src: url('./webfonts/3628E8_0_0.eot');
  src: url('./webfonts/3628E8_0_0.eot?#iefix') format('embedded-opentype'),
  url('./webfonts/3628E8_0_0.woff2') format('woff2'),
  url('./webfonts/3628E8_0_0.woff') format('woff'),
  url('./webfonts/3628E8_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'CoreRhino';
  font-weight: normal;
  src: url('./webfonts/3628E8_1_0.eot');
  src: url('./webfonts/3628E8_1_0.eot?#iefix') format('embedded-opentype'),
  url('./webfonts/3628E8_1_0.woff2') format('woff2'),
  url('./webfonts/3628E8_1_0.woff') format('woff'),
  url('./webfonts/3628E8_1_0.ttf') format('truetype');
}

@font-face {
  font-family: 'CoreRhino';
  font-weight: 500;
  src: url('./webfonts/3628E8_2_0.eot');
  src: url('./webfonts/3628E8_2_0.eot?#iefix') format('embedded-opentype'),
  url('./webfonts/3628E8_2_0.woff2') format('woff2'),
  url('./webfonts/3628E8_2_0.woff') format('woff'),
  url('./webfonts/3628E8_2_0.ttf') format('truetype');
}

@font-face {
  font-family: 'CoreRhino';
  font-weight: bold;
  src: url('./webfonts/3628E8_3_0.eot');
  src: url('./webfonts/3628E8_3_0.eot?#iefix') format('embedded-opentype'),
  url('./webfonts/3628E8_3_0.woff2') format('woff2'),
  url('./webfonts/3628E8_3_0.woff') format('woff'),
  url('./webfonts/3628E8_3_0.ttf') format('truetype');
}

@font-face {
  font-family: 'CoreRhino';
  font-weight: 700;
  src: url('./webfonts/3628E8_4_0.eot');
  src: url('./webfonts/3628E8_4_0.eot?#iefix') format('embedded-opentype'),
  url('./webfonts/3628E8_4_0.woff2') format('woff2'),
  url('./webfonts/3628E8_4_0.woff') format('woff'),
  url('./webfonts/3628E8_4_0.ttf') format('truetype');
}

@font-face {
  font-family: 'CoreRhino';
  font-weight: 900;
  src: url('./webfonts/3628E8_5_0.eot');
  src: url('./webfonts/3628E8_5_0.eot?#iefix') format('embedded-opentype'),
  url('./webfonts/3628E8_5_0.woff2') format('woff2'),
  url('./webfonts/3628E8_5_0.woff') format('woff'),
  url('./webfonts/3628E8_5_0.ttf') format('truetype');
}


body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
